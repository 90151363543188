<!--
 * @Description:停车订单 订单详情 parkOrderDetails
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-09-06 19:17:50
 * @LastEditors: zhoucheng
-->
<!--  -->
<template>
  <div class='mainbody'>
    <van-nav-bar title="停车订单详情"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <div class="content">
      <div class="title">
        <div class="line"></div>
        <div class="title-text">订单详情</div>
      </div>
      <div class="content-top">
        <div class="content-item">
          <div class="content-item-title">订单号:</div>
          <div class="content-item-text">{{orderList.orderSequence}}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">车牌号:</div>
          <div class="content-item-text">{{orderList.plateNumber}}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">车牌颜色:</div>
          <div class="content-item-text">{{orderList.plateColor}}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">入场照片:</div>
          <div class="content-item-text"
               style="color: #19A9FC;"
               @click="handleClickWatchPic">点击查看</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">停车场名称</div>
          <div class="content-item-text"
               @click='handleClickParkName'>{{orderList.parkName}}</div>
        </div>
        <div class="content-item"
             v-show="parkTypeCode===1">
          <div class="content-item-title">泊位号:</div>
          <div class="content-item-text">{{orderList.parkSpaceNumber}}</div>
        </div>
        <div class="content-item"
             style="border-bottom: 1px dashed #dddddd; position: relative;">
          <div class="content-item-title">车辆信用:</div>
          <div class="content-item-text"
               :style="orderList.xinyongCode>0?'color:#39C08F':'color:#FF6C76'">{{orderList.xinyongText}}</div>
          <div v-if="orderList.xinyongLength===1">
            <van-button class="lineButton"
                        @click="handleClickPress(orderList)">追缴</van-button>
          </div>
        </div>
      </div>
      <div class="content-bottom">
        <div class="content-item">
          <div class="content-item-title">停车开始时间:</div>
          <div class="content-item-text">{{orderList.entranceTime}}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">停车结束时间:</div>
          <div class="content-item-text">{{orderList.leaveTime}}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">停车时长:</div>
          <div class="content-item-text">{{orderList.parkDuration}}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">收费规则:</div>
          <div class="content-item-text"
               style="color: #FF6C76;"
               @click="handleQueryBillingRule">{{orderList.billingRuleDefName}}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">应收费用(元):</div>
          <div class="content-item-text">{{orderList.receivableYuan}}</div>
        </div>
        <div class="content-item">
          <div class="content-item-title">已收费用(元):</div>
          <div class="content-item-text">{{orderList.receivedYuan}}</div>
        </div>
      </div>
    </div>
    <div class="bottom-button">
      <van-button class="bottom-button-item"
                  @click="handleClickPay">缴费结算</van-button>
    </div>
    <!-- 收费二维码 -->
    <van-image-preview v-model="qrShow"
                       closeable
                       closeOnPopstate>
      <template v-slot:index>
        <van-row class="qr">
          <vue-qr :correctLevel="3"
                  :autoColor="false"
                  :text="codeUrl"
                  :logoSrc="icon + '?cache'"
                  :logoScale="0.2"
                  :size="200">
          </vue-qr>
        </van-row>
        <div style="text-align: center">支付金额：{{Number(Number(total)/100).toFixed(2)}}</div>
      </template>
    </van-image-preview>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import parkStopTime from '@/utils/parkStopTime'
import { ImagePreview } from 'vant'
import VueQr from 'vue-qr'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: { VueQr },
  data () {
    // 这里存放数据
    return {
      orderList: {},
      currentTime: this.$fmtDate(new Date(), 'yyyy-MM-dd  hh:mm:ss'),
      total: 0,
      qrShow: false,
      codeUrl: '',
      icon: require('@/assets/parkingOrder/weixin.png'),
      parkTypeCode: 0
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.orderList = JSON.parse(this.$route.query.orderItem)
    this.queryParkType()
    if (this.orderList.leaveTime) {
      this.orderList.parkDuration = parkStopTime(this.orderList.leaveTime, this.orderList.entranceTime)
    } else {
      this.orderList.parkDuration = parkStopTime(this.currentTime, this.orderList.entranceTime)
    }

    this.orderList.receivableYuan = Number(Number(this.orderList.receivableMoneyAmount) / 100).toFixed(2)
    this.orderList.receivedYuan = Number(Number(this.orderList.receivedMoneyAmount) / 100).toFixed(2)

    this.total = Number(this.orderList.receivableMoneyAmount - this.orderList.receivedMoneyAmount)

    this.queryXinyong()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 点击停车场名称
    handleClickParkName () {
      this.$toast(this.orderList.parkName)
    },
    // 点击缴费结算
    handleClickPay () {
      const info = {
        orderSequence: this.orderList.orderSequence
      }
      this.$parkingOrder.charge(info).then(res => {
        this.orderList = res.resultEntity

        this.queryXinyong()

        if (this.orderList.leaveTime) {
          this.orderList.parkDuration =
            parkStopTime(this.orderList.leaveTime, this.orderList.entranceTime)
        } else {
          this.orderList.parkDuration =
            parkStopTime(this.currentTime, this.orderList.entranceTime)
        }

        this.orderList.receivableYuan =
          Number(Number(this.orderList.receivableMoneyAmount) / 100).toFixed(2)
        this.orderList.receivedYuan =
          Number(Number(this.orderList.receivedMoneyAmount) / 100).toFixed(2)

        this.total = Number(this.orderList.receivableMoneyAmount -
          this.orderList.receivedMoneyAmount)

        this.queryQrCode(res.resultEntity.receivableMoneyAmount)
      })
    },
    // 获取车辆信用
    queryXinyong () {
      const info = {
        plateNumber: this.orderList.plateNumber,
        numberPlateColorCode: this.orderList.numberPlateColorCode
      }
      this.$parkingOrder.getArrearsOrderInfoByPlateNumberAndCode(info).then(res => {
        // 车辆无欠费订单
        if (res.resultEntity.order.length === 0) {
          this.$set(this.orderList, 'xinyongText', '该车信用良好无欠费')
          this.$set(this.orderList, 'xinyongCode', 1)
          this.$set(this.orderList, 'xinyongLength', 0)
        } else {
          if (this.orderList.orderStatusCode === 2 && res.resultEntity.order.length >= 1) {
            // 已交费车辆有欠费记录
            this.$set(this.orderList, 'xinyongText', '欠费车辆,请追缴停车费')
            this.$set(this.orderList, 'xinyongCode', -1)
            this.$set(this.orderList, 'xinyongLength', 1)
          } else if (this.orderList.orderStatusCode === 1 && res.resultEntity.order.length >= 1) {
            // 未交费车辆有欠费记录
            this.$set(this.orderList, 'xinyongText', '欠费车辆,请追缴停车费')
            this.$set(this.orderList, 'xinyongCode', -1)
            this.$set(this.orderList, 'xinyongLength', 1)
          } else if (this.orderList.orderStatusCode === -1 && res.resultEntity.order.length > 1) {
            // 欠费车辆有多条欠费记录
            this.$set(this.orderList, 'xinyongText', '欠费车辆,请追缴停车费')
            this.$set(this.orderList, 'xinyongCode', -1)
            this.$set(this.orderList, 'xinyongLength', 1)
          } else if (this.orderList.orderStatusCode === -1 && res.resultEntity.order.length === 1) {
            // 欠费车辆有一条欠费记录
            this.$set(this.orderList, 'xinyongText', '欠费车辆,请缴纳停车费')
            this.$set(this.orderList, 'xinyongCode', -1)
            this.$set(this.orderList, 'xinyongLength', 0)
          }
        }
      })
    },
    // 展示二维码
    queryQrCode (val) {
      if (val === 0) {
        this.$toast('该订单暂为0元，无需支付')
      } else {
        const info = {
          orderSequence: this.orderList.orderSequence,
          amount: Number(this.total),
          description: '渝北停车收费'
        }
        this.$parkingOrder.nativePay(info).then(response => {
          this.$toast.clear()
          if (response.resultEntity) {
            this.codeUrl = response.resultEntity
            this.qrShow = true
          } else {
            this.$toast('订单已结清')
          }
        }).catch((err) => {
          // 订单已支付
          if (err.resultCode === '4002') {
            this.$router.push({ name: 'parkingOrder' })
          }
        })
      }
    },
    // 追缴
    handleClickPress (orderList) {
      this.$router.push({
        name: 'lackOrderPay',
        query: {
          plateNumber: orderList.plateNumber,
          plateColor: orderList.plateColor,
          numberPlateColorCode: orderList.numberPlateColorCode
        }
      })
    },
    // 点击查看
    handleClickWatchPic () {
      const info = {
        orderSequence: JSON.parse(this.$route.query.orderItem).orderSequence
      }
      this.$queryDict.getEvidenceByOrderSequence(info).then(res => {
        ImagePreview([this.$imgBaseUrl + res.resultEntity[0].evidencePic])
      }).catch(() => {
        this.$toast('获取图片失败')
      })
    },
    // 查询停车场类型
    queryParkType () {
      const info = {
        parkId: this.orderList.parkId
      }
      this.$queryDict.queryParkInfo(info).then(res => {
        this.parkTypeCode = res.resultEntity.parkTypeCode
      })
    },
    // 点击查询计费规则
    handleQueryBillingRule () {
      this.$router.push({
        name: 'billRuleDetail',
        query: { parkId: this.orderList.parkId }
      })
      // this.$router.push({
      //   name: 'billRuleDetail',
      //   query: { billingRuleDefId: this.orderList.billingRuleDefId }
      // })
    },
    // 顶部返回
    handleClickTopBack () {
      this.$router.push({ name: 'parkingOrder' })
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainbody {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .content {
    width: 92%;
    margin-top: 10px;
    margin-left: 4%;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(221, 221, 221, 0.16);
    opacity: 1;
    border-radius: 4px;
    .title {
      width: 100%;
      height: 39px;
      border-bottom: 1px dashed #dddddd;
      display: flex;
      align-items: center;
      position: relative;
      .line {
        width: 2px;
        height: 18px;
        background: #0aa0f4;
        opacity: 1;
        border-radius: 6px;
        margin-left: 3%;
      }
      .title-text {
        width: 50%;
        margin-left: 2%;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 0px;
        color: #333333;
        opacity: 1;
      }
    }
    .content-top {
      width: calc(100% - 5% -2px);
      padding-left: calc(5% + 2px);
    }
    .content-bottom {
      width: calc(100% - 5% -2px);
      padding-left: calc(5% + 2px);
    }
    .content-item {
      width: 100%;
      height: 33px;
      line-height: 33px;
      display: flex;
      align-items: center;

      .content-item-title {
        width: 100px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
        opacity: 1;
      }
      .content-item-text {
        width: calc(100% - 100px);
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        opacity: 1;
        white-space: nowrap; /*设置不换行*/
        overflow: hidden; /*设置隐藏*/
        text-overflow: ellipsis; /*设置隐藏部分为省略号*/
      }
      .lineButton {
        width: 60px;
        background: #19a9fc;
        height: 33px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
  .bottom-button {
    width: 100%;
    height: 44px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    .bottom-button-item {
      width: 56%;
      background: linear-gradient(130deg, #43b7fa 0%, #009bf3 100%);
      border-radius: 4px;
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      border: none;
      bottom: 5px;
    }
  }
  .qr {
    margin-top: 200px;
  }
}
.van-image-preview {
  /deep/ .van-image-preview__close-icon {
    position: absolute;
    margin-top: 60px !important;
  }
}
</style>
